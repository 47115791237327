
import { ref, onMounted} from 'vue';
import { Modal } from "bootstrap";
import useEmitter from '@/composables/Emmiter';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";
import { exportXlsx } from '@/services/ListaOS'

export default {
  	name: "export-modal-listaOS",    
	setup(){

        const store = useStore();
        const concessionariaInfo:any = ref({});
        const disabledButtonExportar = ref(false);
        const loadingExportFull = ref(false);
        const loadingExportPart = ref(false);
        const showExportFull = ref(false);
        const showExportPart = ref(true);
        const modalExport = ref();        
        const contentResponse = ref();
        const contentEmail = ref("");
        const emitter = useEmitter();
        const payloadListOS = ref()
        const responseExcel = ref()
        const buttonDownload = ref(false)
        const { showTimeAlert } = useAlert();

        function reset(){
            buttonDownload.value = false
            showExportPart.value = true;
            showExportFull.value = true;
            contentResponse.value = "";

        }

        store.watch(() => store.getters.concessionariaSelectedInfo, () => {
            concessionariaInfo.value = Object.assign({}, store.getters.concessionariaSelectedInfo);
        }, { deep: true });             

        async function exportarRelatorio(exportFull = true){

            disabledButtonExportar.value= true;

            try {
                if(exportFull){
                    loadingExportFull.value= true;
                    loadingExportPart.value = true                     
                    emitter.emit("gerarXlsxListaOS");
                    const response = await exportXlsx(concessionariaInfo.value.codConcessionaria, payloadListOS.value);

                    responseExcel.value = response;
                    loadingExportFull.value = true;
                    loadingExportPart.value = false         
                    showExportFull.value =   false          
                    buttonDownload.value = true                   
                }
                
            } catch(e) {
                showTimeAlert('Não foi possível completar a solicitação', "error");
                reset()
            } finally {
                loadingExportFull.value= false;
                loadingExportPart.value= false;
                disabledButtonExportar.value = false;
            }
        }

        emitter.on('retornoXlsxListaOS', (date) => {
            payloadListOS.value = date
        })

        function exportaRelatorio(){
            let blob : any = ''
            blob = new Blob([responseExcel.value], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = `${concessionariaInfo.value.nomeFantasia?.replace(' ','_')}_${payloadListOS.value.date}.xlsx`

            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

             blob = ''
        }

        onMounted(() => {
            const refModal = document.getElementById("export-modal");
            if(refModal){
                document.getElementById("layout_content")?.appendChild(refModal);
            }
            modalExport.value = new Modal(refModal);          
        });

        return { 
            showExportPart,
            showExportFull,            
            contentEmail,
            reset,
            loadingExportFull,
            loadingExportPart,
            disabledButtonExportar,
            modalExport,
            exportaRelatorio,
            exportarRelatorio,
            contentResponse,
            buttonDownload
        };
    },
};
